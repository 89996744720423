import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-folded-text',
  templateUrl: './folded-text.component.html',
  styleUrls: ['./folded-text.component.scss']
})
export class FoldedTextComponent {

  @Input() text: string;

  public folded = true;

  public expandText(): void {
    this.folded = !this.folded;
  }

}
