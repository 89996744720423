import { Component, Input, Inject } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss']
})
export class SocialNetworksComponent {

  @Input() showTitle = true;

  constructor(
    @Inject(SettingsService) private settingsService: SettingsService
  ) {}

  public openInstagram(): void {
    const location = this.settingsService.getSetting('social.network.instagram');
    window.open(location, '_blank');
  }

  public openFacebook(): void {
    const location = this.settingsService.getSetting('social.network.facebook');
    window.open(location, '_blank');
  }

  public openTwitter(): void {
    const location = this.settingsService.getSetting('social.network.twitter');
    window.open(location, '_blank');
  }

  public openWebSite(): void {
    const location = this.settingsService.getSetting('social.network.web');
    window.open(location, '_blank');
  }
}
