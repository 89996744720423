export function clamp(value, min, max) {
  return Math.min(Math.max(min, value), max);
}

export function shadeColor(color, percent) {
  // tslint:disable-next-line:radix one-variable-per-declaration max-line-length
  const f = color.split(','), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = parseInt(f[0].slice(4)), G = parseInt(f[1]), B = parseInt(f[2]);
  return 'rgb(' + (Math.round((t - R) * p) + R) + ',' + (Math.round((t - G) * p) + G) + ',' + (Math.round((t - B) * p) + B) + ')';
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function chunckArray(array, chunckSize) {
  return [].concat.apply([], array.map((elem, i) => i % chunckSize ? [] : [array.slice(i, i + chunckSize)]));
}

export function getRandomElementFromArray(array, nbElement) {
  return array.sort(() => .5 - Math.random()).slice(0, nbElement);
}

export function generateRandomNumber(minValue, maxValue) {
  return Math.floor(Math.random() * maxValue) + minValue;
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function msToTime(miliseconds) {
  return new Date(miliseconds).toISOString().slice(11, -1);
}

export function getTimestamp() {
  return new Date(Date.now()).toISOString();
}
