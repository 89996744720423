import { Injectable, Inject } from '@angular/core';
import { SettingsService } from './settings.service';
import { hexToRgb } from 'src/lib/utils';

@Injectable({
  providedIn: 'root'
})
export class AppStyleInitializerService {

  constructor(
    @Inject(SettingsService) private settings: SettingsService
  ) {}

  public init(): void {
    const backgroundColor = this.settings.getSetting('main-background-color');
    const backgroundColorAsRgb = hexToRgb(backgroundColor);
    const backgroundColorAsRgbString = `${backgroundColorAsRgb.r}, ${backgroundColorAsRgb.g}, ${backgroundColorAsRgb.b}`;
    document.documentElement.style.setProperty('--main-background-color', backgroundColor);
    document.documentElement.style.setProperty('--main-background-color-rgb', backgroundColorAsRgbString);

    document.documentElement.style.setProperty('--main-text-color', this.settings.getSetting('main-text-color'));
  }

}
