import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserCheckerService {

  private browserSizeChecker$ = new BehaviorSubject<boolean>(true);
  public height;
  public width;

  constructor() {
    this.onWindowResize();
    window.addEventListener('resize', () => {
      this.onWindowResize();
    });
  }

  public checkBrowserValidity(): boolean {
    let obsoleteBrowser = true;
    const browser = this.getBrowserVersion();

    // TODO: Change value following specs
    if (browser.name === 'IE') {
      obsoleteBrowser = true;
    } else if (browser.name === 'Firefox' && browser.version >= 51) {
      obsoleteBrowser = false;
    } else if (browser.name === 'Chrome' && browser.version >= 56) {
      obsoleteBrowser = false;
    } else if (browser.name === 'Safari' && browser.version >= 10) {
      obsoleteBrowser = false;
    } else if (browser.name === 'Edge') {
      obsoleteBrowser = false;
    }

    return obsoleteBrowser;
  }

  public checkBrowserSize(): BehaviorSubject<boolean> {
    return this.browserSizeChecker$;
  }

  private getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  private getHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  }

  private onWindowResize() {
    this.height = this.getHeight();
    this.width = this.getWidth();

    // TODO: Change value following specs
    this.browserSizeChecker$.next((this.height < 640 || this.width < 1024) ? false : true);
  }

  private getBrowserVersion() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) { return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]}; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // tslint:disable-next-line:no-conditional-assignment
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {name: M[0], version: M[1]};
  }
}
