import { query as q, style, animate } from '@angular/animations';

export function query(s, a, o = {optional: true}) { return q(s, a, o); }

export const untranslate = style({ transform: 'translateX(0%) translateY(0%)' });
export const leftTranslate = style({ transform: 'translateX(-100%)' });
export const rightTranslate = style({ transform: 'translateX(100%)' });
export const topTranslate = style({ transform: 'translateY(-100%)' });
export const bottomTranslate = style({ transform: 'translateY(100%)' });
export const invisible = style({ opacity: 0 });
export const visible = style({ opacity: 1 });
export const customOpacity = style({ opacity: '*' });

export const translateTo0 = animate('0.7s ease-in-out', untranslate);
export const translateToTop = animate('0.7s ease-in-out', topTranslate);
export const translateToBottom = animate('0.7s ease-in-out', bottomTranslate);
export const translateToLeft = animate('0.7s ease-in-out', leftTranslate);
export const translateToRight = animate('0.7s ease-in-out', rightTranslate);
export const toInvisible = animate('0.7s ease-in-out', invisible);
export const toVisible = animate('0.7s ease-in-out', visible);
export const toCustomOpacity = animate('0.7s ease-in-out', customOpacity);
