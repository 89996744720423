import { Injectable, Inject } from '@angular/core';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { ShowControl } from '../models/showControl';
import { ShowService } from './show-service';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  private subscription$: Subject<boolean>;

  constructor(
    @Inject(MqttService) private mqttService: MqttService,
    @Inject(SettingsService) private settingsService: SettingsService,
    @Inject(ShowService) private showService: ShowService
  ) {

    if (environment.useMqttCredential) {
      mqttService.connect(environment.mqttCredential);
    }
  }

  public connectTopic() {
    this.subscription$ = new Subject<boolean>();

    const {topics: {showcontrol, state}} = environment;
    const showId = this.getShowId();

    const stateTopic = `${showId}/${state}`;
    this.mqttService
      .observe(stateTopic)
      .pipe(takeUntil(this.subscription$))
      .subscribe((message: IMqttMessage) => {
        const showState = +message.payload.toString();
        this.showService.setState(showState);
      });

    const controlTopic = `${showId}/${showcontrol}`;
    this.mqttService
      .observe(controlTopic)
      .pipe(takeUntil(this.subscription$))
      .subscribe((message: IMqttMessage) => {
        const dataStr = message.payload.toString();
        if (dataStr) {
          const data: ShowControl = JSON.parse(dataStr);
          const {track, trackcontrol, timeCode} = data;
          this.showService.setTrack(track);
          this.showService.setTrackControl(+trackcontrol);
          this.showService.setTrackTimecode(+timeCode);
        }
      });
  }

  public getShowId(): string {
    return this.settingsService.getSetting('show-id');
  }
}
