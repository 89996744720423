import { Component, Inject } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {

  constructor(
    @Inject(SettingsService) private settingsService: SettingsService
  ) {}

  public locateShow(): void {
    const location = this.settingsService.getSetting('home.show.location');
    window.open(location, '_blank');
  }

}
