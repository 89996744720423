import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-home-back-button',
  templateUrl: './home-back-button.component.html',
  styleUrls: ['./home-back-button.component.scss']
})
export class HomeBackButtonComponent {

  @Output() clicked = new EventEmitter()

  public onClick(): void {
    this.clicked.emit();
  }
}
