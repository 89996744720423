import { ShowState } from 'src/app/models/showState';
import { TrackControl } from 'src/app/models/trackControl';

export namespace ShowStoreModule {

  export enum ActionTypes {
    SET_STATE = '[Show] Set state',
    SET_TRACK = '[Show] Set track',
    SET_TRACK_TIMECODE = '[Show] Set track timecode',
    SET_TRACK_CONTROL = '[Show] Set track control'
  }

  export class SetState {
    readonly type = ActionTypes.SET_STATE;
    constructor(public payload: ShowState) {}
  }

  export class SetTrack {
    readonly type = ActionTypes.SET_TRACK;
    constructor(public payload: string) {}
  }

  export class SetTrackTimecode {
    readonly type = ActionTypes.SET_TRACK_TIMECODE;
    constructor(public payload: number) {}
  }

  export class SetTrackControl {
    readonly type = ActionTypes.SET_TRACK_CONTROL;
    constructor(public payload: TrackControl) {}
  }

  export type Actions =
    SetState |
    SetTrack |
    SetTrackTimecode |
    SetTrackControl;
}
