import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.scss']
})
export class HomeVideoComponent {

  @Input() videoSrc: string;

}
