import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { LocalizeRouterService } from 'localize-router';

@Injectable({
  providedIn: 'root'
})
export class AppLanguageService {

  private lang$ = new BehaviorSubject<string>('fr');
  private lang: string;

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(LocalizeRouterService) private localizeService: LocalizeRouterService,
    @Inject(TranslateService) private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('fr');
    this.changeLanguage(this.translateService.currentLang);
  }

  public changeLanguage(lang: string) {
    this.lang = lang;
    this.translateService.use(this.lang);
    this.localizeService.changeLanguage(this.lang);
    this.lang$.next(this.lang);
  }

  public getLocale(): Observable<string> {
    return this.lang$;
  }
}
