import { NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { LocalizeRouterSettings, LocalizeRouterModule, LocalizeParser, ManualParserLoader } from 'localize-router';

// Services
import { Routes, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// Component
import { HomeViewComponent } from './home/views/home-view/home-view.component';

export function createTranslateLoader(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  return new ManualParserLoader(translate, location, settings, ['en', 'fr'], 'routes.');
}

const routes: Routes = [
  {path: '', component: HomeViewComponent},
  {path: '*', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: createTranslateLoader,
        deps: [TranslateService, Location, LocalizeRouterSettings]
      }
    })
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule
  ]
})
export class AppRoutingModule { }
