import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SoundStoreModule } from '../state/sound.action';
import { SoundState } from '../state/sound.reducer';
import { Store, select } from '@ngrx/store';
import { selectAudioType$ } from '../state/sound.selector';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class SoundService {

  constructor(
    @Inject(Store) private store: Store<SoundState>,
    @Inject(DeviceDetectorService) private deviceDetectorService: DeviceDetectorService
  ) {
  }

  public setAudioSource(audioSrc: string) {
    const os = this.deviceDetectorService.os;

    if (os === 'iOS') {
      this.store.dispatch(new SoundStoreModule.SetAudioSrc(audioSrc + '.m3u8'));
    } else {
      this.store.dispatch(new SoundStoreModule.SetAudioSrc(audioSrc + '.mp3'));
    }
  }

  public getAudioSource(): Observable<string> {
    return this.store.pipe(select(selectAudioType$));
  }

}
