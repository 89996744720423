import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMzKioskLibModule } from '@mazedia/ng-kiosk';
import { LottieAnimationViewModule } from 'ng-lottie';
import { TranslateModule } from '@ngx-translate/core';
import { SplashscreenComponent } from './components/splashscreen/splashscreen.component';
import { MainLogoComponent } from './components/main-logo/main-logo.component';
import { FooterLogoComponent } from './components/footer-logo/footer-logo.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { FullScreenPopinComponent } from './components/full-screen-popin/full-screen-popin.component';
import { SocialNetworksComponent } from './components/social-networks/social-networks.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

@NgModule({
  declarations: [
    SplashscreenComponent,
    MainLogoComponent,
    FooterLogoComponent,
    LanguageSelectorComponent,
    FullScreenPopinComponent,
    SocialNetworksComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgMzKioskLibModule,
    LottieAnimationViewModule.forRoot()
  ],
  exports: [
    TranslateModule,
    NgMzKioskLibModule,
    LottieAnimationViewModule,
    SplashscreenComponent,
    MainLogoComponent,
    FooterLogoComponent,
    LanguageSelectorComponent,
    FullScreenPopinComponent,
    SocialNetworksComponent,
    SpinnerComponent
  ]
})
export class SharedModule { }
