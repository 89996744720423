export const environment = {
  production: true,
  appId: 'fr.live.translate.cosmo.av.alpha',
  mqtt: {
    hostname: 'wsmqtt.rabbitmq.mazedia.fr',
    port: 443,
    protocol: 'wss',
    path: '/ws'
  },
  useMqttCredential: true,
  mqttCredential: {
    username: 'cosmoav',
    password: '1vKv3uGP'
  },
  topics: {
    state: 'state',
    showcontrol: 'showcontrol'
  }
};
