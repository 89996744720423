
import { ShowStoreModule } from './show.action';
import { TrackControl } from 'src/app/models/trackControl';
import { ShowState } from 'src/app/models/showState';

export interface StoreShowState {
  showState: ShowState;
  track: string;
  trackTimecode: number;
  trackControl: TrackControl;
}

export const initialState: StoreShowState = {
  showState: null,
  track: null,
  trackTimecode: null,
  trackControl: null
};

export function showReducer(state = initialState, action: ShowStoreModule.Actions): StoreShowState {

  switch (action.type) {
    case ShowStoreModule.ActionTypes.SET_STATE:
      return {
        ...state,
        showState: action.payload
      };

    case ShowStoreModule.ActionTypes.SET_TRACK:
      return {
        ...state,
        track: action.payload
      };

    case ShowStoreModule.ActionTypes.SET_TRACK_CONTROL:
      return {
        ...state,
        trackControl: action.payload
      };

    case ShowStoreModule.ActionTypes.SET_TRACK_TIMECODE:
      return {
        ...state,
        trackTimecode: action.payload
      };

    default:
      return state;
  }

}
