import { Component, Input } from '@angular/core';

export interface EventDate {
  dayNb: string;
  dayStr: string;
  month: string;
  hour: string;
}

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent {

  @Input() eventList: EventDate[];

}
