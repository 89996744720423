import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Papa } from 'ngx-papaparse';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';

@Injectable()
export class PlanningService {

  private nextShow: Date;
  private dateList: Date[];

  private init$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(Papa) private papa: Papa
  ) {
    this.http.get('./assets/datas/calendrier_saison2020.csv', {responseType: 'text'})
      .pipe(first())
      .subscribe((csvData) => {
        this.papa.parse(csvData, {
          complete: (result) => {
            let csvData = result.data;
            csvData = csvData.slice(1, csvData.length - 1);
            csvData = csvData.filter(data => data[1]);
            this.dateList = csvData.map((data) => this.createDateFromData(data));

            this.parseResult();
            this.init$.next(true);
          }
        });
      });
  }

  public isPlanningInitialized(): Observable<boolean> {
    return this.init$;
  }

  public getCalendarEvents(): CalendarEvent[] {
    const events: CalendarEvent[] = [];

    this.dateList.forEach((date) => {
      const event: CalendarEvent = {
        start: date,
        end: date,
        title: 'show'
      };

      events.push(event);
    });

    return events;
  }

  public getNextShow(): Date {
    return this.nextShow;
  }

  public getClosestDate(): Date {
    const todayDate = this.getCurrentDate();

    const closestDateFromToday = this.dateList.find((date) => {
      return (date.getTime() - todayDate.getTime()) > 0;
    });

    return closestDateFromToday;
  }

  public getNextDates(): Date[] {
    const index = Math.max(0, this.dateList.indexOf(this.getClosestDate()));
    const dataList: any[] = this.dateList.slice(index, Math.min(index + 3, this.dateList.length - 1));

    return dataList;
  }

  public getTodayEvents(): CalendarEvent[] {
    const todayDate = this.getCurrentDate();
    todayDate.setHours(0, 0, 0, 0);

    const todayDateList = this.dateList.filter((date: Date) => {
      const dateToCompare = new Date(date);
      dateToCompare.setHours(0, 0, 0, 0);

      return dateToCompare.getTime() === todayDate.getTime();
    });

    const events: CalendarEvent[] = [];

    todayDateList.forEach((date) => {
      const event: CalendarEvent = {
        start: date,
        end: date,
        title: 'show'
      };

      events.push(event);
    });

    return events;
  }

  private parseResult() {
    const todayDate = this.getCurrentDate();

    const index = Math.max(0, this.dateList.indexOf(this.getClosestDate()));
    const date = this.dateList[index];
    this.nextShow = date;
  }

  private createDateFromData(data: any) {
    const curDate = new Date(data[0]);
    const curTime = data[1].split(':');
    curDate.setHours(curTime[0]);
    curDate.setMinutes(curTime[1]);

    return curDate;
  }

  public getCurrentDate(): Date {
    return new Date(new Date().toLocaleString('en-US', {timeZone: 'Europe/Paris'}));
  }

}
