// Angular Modules
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

// NGRX / Store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { REDUCER_TOKEN, appEffects, getReducers } from './store/root-reducers';

// Librairies
import { MqttModule } from 'ngx-mqtt';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { NgMzKioskLibModule } from '@mazedia/ng-kiosk';

// Services
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppConfigService } from './services/app-config.service';

// Utils
import { environment } from 'src/environments/environment';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

// Components
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ShowModule } from './show/show.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

const appInitializer = (appConfig: AppConfigService) => {
  return async () => {
    await appConfig.loadAppConfig();
  };
};

export class HammerConfig extends HammerGestureConfig {
  overrides = {
      // override hammerjs default configuration
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const mqttOptions: any = environment.mqtt;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgMzKioskLibModule.forRoot(environment.appId),
    StoreModule.forRoot(REDUCER_TOKEN),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({
      name: '[]',
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    MqttModule.forRoot(mqttOptions),
    AppRoutingModule,
    SharedModule,
    HomeModule,
    ShowModule
  ],
  providers: [
    AppConfigService,
    DeviceDetectorService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
