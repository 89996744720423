import { Injectable, Inject } from '@angular/core';
import { AppState } from '../store/root-reducers';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ShowState } from '../models/showState';
import { selectShowState$ as selectShowState$, selectShowTrack$, selectShowTrackTimecode$, selectShowTrackControl$ } from '../store/show-state/show.selector';
import { ShowStoreModule } from '../store/show-state/show.action';
import { TrackControl } from '../models/trackControl';
import { SettingsService } from './settings.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ShowService {

  constructor(
    @Inject(Store) private store: Store<AppState>,
    @Inject(SettingsService) private settingsService: SettingsService,
    @Inject(TranslateService) private translateService: TranslateService
  ) {
  }

  public setState(state: ShowState): void {
    this.store.dispatch(new ShowStoreModule.SetState(state));
  }

  public currentState(): Observable<ShowState> {
    return this.store.pipe(select(selectShowState$));
  }

  public setTrack(trackNumber: string): void {
    const trackPattern = this.settingsService.getSetting('track-pattern');
    const locale = this.translateService.currentLang;
    const finalTrack = trackPattern.replace('%lang', locale).replace('%number', trackNumber);
    this.store.dispatch(new ShowStoreModule.SetTrack(finalTrack));
  }

  public currentTrack(): Observable<string> {
    return this.store.pipe(select(selectShowTrack$));
  }

  public setTrackTimecode(timecode: number): void {
    this.store.dispatch(new ShowStoreModule.SetTrackTimecode(timecode));
  }

  public currentTrackTimecode(): Observable<number> {
    return this.store.pipe(select(selectShowTrackTimecode$));
  }

  public setTrackControl(control: TrackControl): void {
    this.store.dispatch(new ShowStoreModule.SetTrackControl(control));
  }

  public currentTrackControl(): Observable<TrackControl> {
    return this.store.pipe(select(selectShowTrackControl$));
  }
}
