import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppLanguageService } from 'src/app/services/app-language.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {

  public lang$: Observable<string>;

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(AppLanguageService) private languageService: AppLanguageService
  ) {
    this.lang$ = this.languageService.getLocale();
  }

  public changeLanguage(lang: string) {
    this.languageService.changeLanguage(lang);
  }
}
