import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Handle the application configuration read from assets/config.json file
 */
@Injectable()
export class AppConfigService {

  private TAG = '[AppConfigService]';
  private appConfig: any;

  constructor(
    @Inject(HttpClient) private http: HttpClient
  ) {
    console.log(this.TAG, 'constructor');
  }

  public async loadAppConfig() {
    try {
      const data = await this.http.get('./assets/config.json').toPromise();
      console.log(this.TAG, 'configuration', data);
      this.appConfig = data;
    } catch (reason) {
      alert('No config file ./assets/config.json found');
    }
  }

  public getConfig() {
    return this.appConfig;
  }
}
