import { Component, OnInit, ViewChild, Inject, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SoundService } from '../../services/sound.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-audio-test',
  templateUrl: './audio-test.component.html',
  styleUrls: ['./audio-test.component.scss']
})
export class AudioTestComponent implements OnInit, OnDestroy {

  @Output() testSucceed = new EventEmitter();
  @Output() testFailed = new EventEmitter();

  public animationConfig = {
    path: '',
    renderer: 'svg',
    autoplay: true,
    loop: true
  };

  constructor(
    @Inject(SoundService) private soundService: SoundService,
    @Inject(DeviceDetectorService) private deviceDetectorService: DeviceDetectorService,
    @Inject(SettingsService) private settingsService: SettingsService
  ) { }

  ngOnInit() {
    const animationPath = this.settingsService.getSetting('show-in-progress-animation');
    this.animationConfig.path = animationPath;

    const os = this.deviceDetectorService.os;

    this.soundService.setAudioSource('https://storage.gra.cloud.ovh.net/v1/AUTH_ec034d65bf444e258e4ba95429f1f092/cosmoav/audio-preview/cosmoavsables-preview');
  }

  ngOnDestroy(): void {
    this.soundService.setAudioSource(null);
  }

  public accept(): void {
    this.testSucceed.emit();
  }

  public cancel(): void {
    this.testFailed.emit();
  }
}
