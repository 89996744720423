import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private TAG = '[SettingsService]';
  private settings;

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(TranslateService) private translateService: TranslateService
  ) {}

  public async loadAppSettings(): Promise<void> {
    try {
      const data = await this.http.get('./assets/settings.json').toPromise();
      console.log(this.TAG, 'load settings', data);
      this.settings = data;
    } catch (reason) {
      alert('No settings file');
    }
  }

  public getSetting(key: string, locale?: string, orderBy?: string): any {
    locale = locale ? locale : this.translateService.currentLang;

    let value = locale ? this.settings[locale][key] : null;
    if (!value) {
      const defaultKey = 'default';
      value = this.settings[defaultKey][key];
    }

    if (Array.isArray(value) && orderBy) {
      value.sort((a, b) => {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      });
    }

    return value;
  }
}
