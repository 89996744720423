import { Component, HostBinding } from '@angular/core';
import { trigger, transition } from '@angular/animations';
import { toInvisible, visible } from 'src/lib/animations';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
  animations: [
    trigger('fade', [
      transition(':leave', [visible, toInvisible]),
    ])
  ]
})
export class SplashscreenComponent {
  @HostBinding('@fade') fade: any;
}
