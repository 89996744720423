import { AppState } from '../root-reducers';
import { createSelector } from '@ngrx/store';
import { StoreShowState } from './show.reducer';

export const selectStoreShowState$ = (state: AppState) => state.show;

export const selectShowState$ =
  createSelector(selectStoreShowState$, (state: StoreShowState) => state.showState);

export const selectShowTrack$ =
  createSelector(selectStoreShowState$, (state: StoreShowState) => state.track);

export const selectShowTrackTimecode$ =
  createSelector(selectStoreShowState$, (state: StoreShowState) => state.trackTimecode);

export const selectShowTrackControl$ =
  createSelector(selectStoreShowState$, (state: StoreShowState) => state.trackControl);
