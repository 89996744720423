// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowRoutingModule } from './show.routing.module';
import { SharedModule } from '../shared/shared.module';
import { PapaParseModule } from 'ngx-papaparse';
import { StoreModule } from '@ngrx/store';

import { soundReducerFeatureKey, soundReducer } from './state/sound.reducer';

// Services
import { SoundService } from './services/sound.service';

// Views
import { ShowViewComponent } from './views/show-view/show-view.component';
import { EndShowViewComponent } from './views/end-show-view/end-show-view.component';

// Components
import { AudioTestComponent } from './components/audio-test/audio-test.component';
import { HiddenAudioPlayerComponent } from './components/hidden-audio-player/hidden-audio-player.component';
import { ShowComponent } from './components/show/show.component';
import { HomeBackButtonComponent } from './components/home-back-button/home-back-button.component';

@NgModule({
  declarations: [
    ShowViewComponent,
    EndShowViewComponent,
    AudioTestComponent,
    HiddenAudioPlayerComponent,
    ShowComponent,
    HomeBackButtonComponent
  ],
  providers: [
    SoundService
  ],
  imports: [
    CommonModule,
    SharedModule,
    ShowRoutingModule,
    PapaParseModule,
    StoreModule.forFeature(soundReducerFeatureKey, soundReducer)
  ],
  exports: [
    ShowViewComponent,
    EndShowViewComponent
  ]
})
export class ShowModule { }
