import { SoundStoreModule } from './sound.action';


export interface SoundState {
  audioType: string;
}

export const initialState: SoundState = {
  audioType: ''
};

export function soundReducer(state = initialState, action: SoundStoreModule.Actions): SoundState {

  switch (action.type) {
    case SoundStoreModule.ActionTypes.SET_AUDIO_SRC:
      return {
        ...state,
        audioType: action.payload
      }

    default:
      return state;
  }

}

export const soundReducerFeatureKey = 'sound';
