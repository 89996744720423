import { Component, OnInit, Inject } from '@angular/core';
import { StandByManager, StandByManagerEvent } from 'src/lib/stand-by-manager';
import { BrowserCheckerService } from './services/browser-checker.service';
import { BrokerService } from './services/broker.service';
import { SettingsService } from './services/settings.service';
import { AppStyleInitializerService } from './services/app-style-initializer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  obsoleteBrowser: boolean;
  public splashscreenHide = false;

  constructor(
    @Inject(BrowserCheckerService) private browserChecker: BrowserCheckerService,
    @Inject(BrokerService) private brokerService: BrokerService,
    @Inject(SettingsService) private settings: SettingsService,
    @Inject(AppStyleInitializerService) private styleInitializer: AppStyleInitializerService
  ) {}

  async ngOnInit() {
    await this.settings.loadAppSettings();
    this.styleInitializer.init();

    this.obsoleteBrowser = this.browserChecker.checkBrowserValidity();
    this.browserChecker.checkBrowserSize().subscribe((val: boolean) => {
      // Do something for bad screen size
    });

    setTimeout(() => {
      this.splashscreenHide = true;
      this.brokerService.connectTopic();
    }, 2000);
  }
}
