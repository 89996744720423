import { Component, Input } from '@angular/core';

export interface HomeBlockItem {
  title: string;
  text: string;
  buttonText: string;
  buttonLink: string;
}

@Component({
  selector: 'app-home-block',
  templateUrl: './home-block.component.html',
  styleUrls: ['./home-block.component.scss']
})
export class HomeBlockComponent {

  @Input() item: HomeBlockItem;

  public openLink(link: string): void {
    console.log('Link', link);

    window.open(link, '_blank');
  }
}
