import { Component, Inject, HostBinding, OnInit } from '@angular/core';
import { trigger, transition, style, group, query, animate } from '@angular/animations';
import { BaseComponent } from '@mazedia/ng-kiosk';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppLanguageService } from 'src/app/services/app-language.service';
import { invisible, visible, untranslate } from 'src/lib/animations';
import { PlanningService } from '../../services/planning.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ShowState } from 'src/app/models/showState';
import { ShowService } from 'src/app/services/show-service';
import { HomeBlockItem } from '../../components/home-block/home-block.component';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        invisible,
        query('.home-content', style({ transform: 'translateY(15%)' })),
        group([
          animate('0.6s linear', visible),
          query('.home-content', animate('0.7s ease-out', untranslate)),
        ])
      ])
    ])
  ]
})
export class HomeViewComponent extends BaseComponent implements OnInit {

  @HostBinding('@fade') fade: any;

  public locale$: Observable<string>;
  public planningInitiliazed$: Observable<boolean>;
  public nextShow: Date = null;
  public showStarted = false;
  public showState: ShowState;

  public legalNotice = false;
  public fullCalendar = false;
  public videoSrc: string;

  public homeBlockList: HomeBlockItem[];

  constructor(
    @Inject(AppLanguageService) private languageService: AppLanguageService,
    @Inject(PlanningService) private planningService: PlanningService,
    @Inject(SettingsService) private settingsService: SettingsService,
    @Inject(ShowService) private showService: ShowService
  ) {
    super();
  }

  ngOnInit() {
    this.planningInitiliazed$ = this.planningService.isPlanningInitialized();
    this.locale$ = this.languageService.getLocale();

    this.showService.currentState().pipe(takeUntil(this.unsubscribe$)).subscribe((showState: ShowState) => {
      if (showState !== null && showState !== undefined) {
        this.handleShowState(showState);
        this.showState = showState;
      }
    });

    this.planningInitiliazed$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((init) => {
        if (init) {
          this.nextShow = this.planningService.getNextShow();
        }
      });

    this.locale$.pipe(takeUntil(this.unsubscribe$)).subscribe((locale: string) => {
      const videoKey = 'home.video.source';
      this.videoSrc = this.settingsService.getSetting(videoKey, locale);
    });

    this.homeBlockList = this.settingsService.getSetting('home-block-list');

  }

  public handleShowState(state: ShowState): void {
    switch (state) {
      case ShowState.STARTUP:
      case ShowState.SHOW:
        this.showStarted = true;
        break;

      case ShowState.END:
      default:
        this.showStarted = false;
        break;
    }
  }

  public showFullCalendar(): void {
    this.fullCalendar = true;
  }

  public closeFullCalendar(): void {
    this.fullCalendar = false;
  }

  public openLegalNotice(): void {
    this.legalNotice = true;
  }

  public closeLegalNotice(): void {
    this.legalNotice = false;
  }
}
