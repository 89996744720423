import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-end-show-view',
  templateUrl: './end-show-view.component.html',
  styleUrls: ['./end-show-view.component.scss']
})
export class EndShowViewComponent implements OnInit {

  constructor(
    @Inject(Router) private router: Router
  ) { }

  ngOnInit() {
  }

  public backToHome(): void {
    this.router.navigate(['']);
  }

}
