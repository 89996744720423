import { Component, OnInit, ViewChild, Inject, Input, OnChanges } from '@angular/core';
import { SoundService } from '../../services/sound.service';
import { ShowService } from 'src/app/services/show-service';
import { BaseComponent } from '@mazedia/ng-kiosk';
import { takeUntil } from 'rxjs/operators';
import { TrackControl } from 'src/app/models/trackControl';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-hidden-audio-player',
  templateUrl: './hidden-audio-player.component.html',
  styleUrls: ['./hidden-audio-player.component.scss']
})
export class HiddenAudioPlayerComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() useCurrentTime: boolean;

  @ViewChild('audioPlayer', {static: true}) audioPlayer;

  public deltaTimecode: number;

  constructor(
    @Inject(SoundService) private soundService: SoundService,
    @Inject(SettingsService) private settingsService: SettingsService,
    @Inject(ShowService) private showService: ShowService
  ) {
    super();
  }

  ngOnChanges() {
    if (!this.useCurrentTime) {
      this.audioPlayer.nativeElement.currentTime = 0;
    }
  }

  ngOnInit() {
    this.deltaTimecode = this.settingsService.getSetting('delta-timecode');

    this.soundService.getAudioSource()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((audioSource: string) => {
      this.selectSound(audioSource);
    });

    this.showService.currentTrackTimecode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((timecode: number) => {
        if (this.useCurrentTime) {
          const newTime = (timecode / 1000);
          if (Math.abs(this.audioPlayer.nativeElement.currentTime - newTime) > this.deltaTimecode) {
            this.audioPlayer.nativeElement.currentTime = newTime;
          }
        }
      });

    this.showService.currentTrackControl()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((control: TrackControl) => {
        switch (control) {
          case TrackControl.PAUSE:
          case TrackControl.STOP:
            this.stopSound();
            break;

          case TrackControl.PLAY:
            this.playSound();
            break;
        }
      });
  }

  private playSound(): void {
    this.audioPlayer.nativeElement.play();
  }

  private stopSound(): void {
    this.audioPlayer.nativeElement.pause();
  }

  public selectSound(src: string) {
    if (!src) {
      this.audioPlayer.nativeElement.src = null;
    } else {
      this.audioPlayer.nativeElement.src = src;
      this.playSound();
    }
  }

}
