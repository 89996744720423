export namespace SoundStoreModule {

  export enum ActionTypes {
    SET_AUDIO_SRC = '[App] Set audio source'
  }

  export class SetAudioSrc {
    readonly type = ActionTypes.SET_AUDIO_SRC;
    constructor(public payload: string) {}
  }

  export type Actions =
    SetAudioSrc;
}
