// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home.routing.module';
import { SharedModule } from '../shared/shared.module';

// Utils
import { DateAdapter, CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Views
import { HomeViewComponent } from './views/home-view/home-view.component';

// Services
import { PlanningService } from './services/planning.service';

// Components
import { FoldedTextComponent } from './components/folded-text/folded-text.component';
import { NextSessionsComponent } from './components/next-sessions/next-sessions.component';
import { NextShowComponent } from './components/next-show/next-show.component';
import { LocationComponent } from './components/location/location.component';
import { HomeVideoComponent } from './components/home-video/home-video.component';
import { FullCalendarComponent } from './components/full-calendar/full-calendar.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { HomeBlockComponent } from './components/home-block/home-block.component';

@NgModule({
  declarations: [
    HomeViewComponent,
    FoldedTextComponent,
    NextShowComponent,
    NextSessionsComponent,
    LocationComponent,
    HomeVideoComponent,
    FullCalendarComponent,
    EventListComponent,
    HomeBlockComponent
  ],
  providers: [
    PlanningService
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  exports: [
    HomeViewComponent
  ]
})
export class HomeModule { }
