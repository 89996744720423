import { Component, Output, EventEmitter, HostBinding, OnDestroy, Renderer2 } from '@angular/core';
import { invisible, toVisible, visible, toInvisible } from 'src/lib/animations';
import { transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-full-screen-popin',
  templateUrl: './full-screen-popin.component.html',
  styleUrls: ['./full-screen-popin.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [invisible, toVisible]),
      transition(':leave', [visible, toInvisible]),
    ])
  ]
})
export class FullScreenPopinComponent implements OnDestroy {
  @HostBinding('@fade') fade: any;
  @Output('closed') closed = new EventEmitter();

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'modal-open');
   }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  public closePopin(): void {
    this.closed.emit();
  }
}
