import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LocalizeRouterModule } from 'localize-router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ShowViewComponent } from './views/show-view/show-view.component';
import { EndShowViewComponent } from './views/end-show-view/end-show-view.component';

export const showRoutes: Routes = [
  {
    path: 'show',
    component: ShowViewComponent
  },
  {
    path: 'end-show',
    component: EndShowViewComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(showRoutes),
    LocalizeRouterModule.forChild(showRoutes)
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule
  ]
})
export class ShowRoutingModule {
  constructor() {
    _('routes.show');
    _('routes.end-show');
  }
}
