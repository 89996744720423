import { Component, Inject, OnInit, Input } from '@angular/core';
import { LocalizeRouterService } from 'localize-router';
import { Router } from '@angular/router';
import { ShowState } from 'src/app/models/showState';

@Component({
  selector: 'app-next-show',
  templateUrl: './next-show.component.html',
  styleUrls: ['./next-show.component.scss']
})
export class NextShowComponent {

  public showEnum: typeof ShowState = ShowState;

  @Input() showHour: string;
  @Input() showState: ShowState;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(LocalizeRouterService) private localize: LocalizeRouterService
  ) {}

  public joinShow(): void {
    this.router.navigate([this.localize.translateRoute('/show')]);
  }

}
