import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { showReducer, StoreShowState } from './show-state/show.reducer';

const reducers = {
  show: showReducer
};

export function getReducers() {
  return reducers;
}

export interface AppState {
  show: StoreShowState;
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

export const appEffects = [];
