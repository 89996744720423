import { Component, OnChanges, Inject, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Router } from '@angular/router';
import { ShowService } from 'src/app/services/show-service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@mazedia/ng-kiosk';
import { ShowState } from 'src/app/models/showState';
import { LocalizeRouterService } from 'localize-router';

declare var Swiper: any;

interface SlideItem {
  title: string;
  text: string;
  animation: string;
  buttonText: string;
  buttonIcon: string;
  buttonAction: string;
}

@Component({
  selector: 'app-show-view',
  templateUrl: './show-view.component.html',
  styleUrls: ['./show-view.component.scss']
})
export class ShowViewComponent extends BaseComponent implements OnInit, OnChanges {

  public swiper: any;

  public slideList: SlideItem[];
  public audioTesting = false;
  public showIsLaunched = false;

  constructor(
    @Inject(SettingsService) private settingsService: SettingsService,
    @Inject(Router) private router: Router,
    @Inject(ShowService) private showService: ShowService,
    @Inject(LocalizeRouterService) private localize: LocalizeRouterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.slideList = this.settingsService.getSetting('show-slide-list');

    this.showService.currentState().pipe(takeUntil(this.unsubscribe$)).subscribe((state: ShowState) => {
      if (state !== null && state !== undefined) {
        this.handleShowState(state);
      }
    });

    this.initSlider();
  }

  ngOnChanges(): void {
    if (this.swiper) {
      setTimeout(() => {
        this.swiper.destroy(true, true);
        this.initSlider();
      }, 100);
    }
  }

  public handleShowState(state: ShowState): void {
    switch (state) {
      case ShowState.STARTUP:
        break;

      case ShowState.SHOW:
        break;

      case ShowState.END:
      default:
        this.goToEndShowView();
        break;
    }
  }

  public backToHome(): void {
    this.router.navigate(['']);
  }

  public stopAudioTest(): void {
    this.audioTesting = false;
  }

  public stopShow(): void {
    this.showIsLaunched = false;
  }

  public handleSlideButtonClick(action: string): void {
    switch (action) {
      case 'testAudio':
        this.testAudio();
        break;

      case 'launchShow':
        this.launchShow();
        break;
    }
  }

  public audioTestSucceed(): void {
    this.nextSlide();
    this.stopAudioTest();
  }

  public nextSlide(): void {
    this.swiper.slideNext();
  }

  public passSlide(): void {
    this.swiper.slideTo(4);
  }

  private initSlider(): void {
    const pagination = {
      el: '.swiper-pagination',
    };

    // Get nb child
    this.swiper = new Swiper('#show-swiper', {
      centeredSlides: true,
      slidesPerView: 1,
      pagination,
      loop: false
    });

    setTimeout(() => {
      this.swiper.update();
    }, 200);
  }

  private testAudio(): void {
    this.audioTesting = true;
  }

  private launchShow(): void {
    this.showIsLaunched = true;
  }

  private goToEndShowView(): void {
    this.router.navigate([this.localize.translateRoute('/end-show')]);
  }
}
