import { Component, Input, Inject, OnInit } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { PlanningService } from '../../services/planning.service';
import { EventDate } from '../event-list/event-list.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.scss']
})
export class FullCalendarComponent implements OnInit {

  @Input() locale: string;

  public view = CalendarView.Month;

  public viewDate;
  public refresh = new Subject();

  public events: CalendarEvent[];

  public currentDateEventList: EventDate[] = [];

  constructor(
    @Inject(PlanningService) private planningService: PlanningService
  ) {}

  ngOnInit(): void {
    this.viewDate = this.planningService.getCurrentDate();

    this.events = this.planningService.getCalendarEvents();

    const todayEvents = this.planningService.getTodayEvents();

    this.dayClicked({date: this.viewDate, events: todayEvents});
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
    this.currentDateEventList = [];

    events.forEach((event: CalendarEvent) => {
      const date = event.start;
      this.currentDateEventList.push({
        dayStr: formatDate(date, 'EEEE', this.locale),
        dayNb: formatDate(date, 'dd', this.locale),
        month: formatDate(date, 'MMMM yyyy', this.locale),
        hour: formatDate(date, 'HH:mm', this.locale),
      });
    });
  }
}
