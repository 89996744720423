import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ShowService } from 'src/app/services/show-service';
import { BaseComponent } from '@mazedia/ng-kiosk';
import { takeUntil } from 'rxjs/operators';
import { ShowState } from 'src/app/models/showState';
import { SoundService } from '../../services/sound.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent extends BaseComponent implements OnInit, OnDestroy {

  public showStarted = false;

  public showWaitingConfig = {
    path: '',
    renderer: 'svg',
    autoplay: true,
    loop: true
  };

  public showInProgressConfig = {
    path: '',
    renderer: 'svg',
    autoplay: true,
    loop: true
  };

  constructor(
    @Inject(ShowService) private showService: ShowService,
    @Inject(SoundService) private soundService: SoundService,
    @Inject(SettingsService) private settingsService: SettingsService
  ) {
    super();
  }

  ngOnInit() {
    const showInProgessAnimationPath = this.settingsService.getSetting('show-in-progress-animation');
    const showWaitingAnimationPath = this.settingsService.getSetting('show-waiting-animation');

    this.showInProgressConfig.path = showInProgessAnimationPath;
    this.showWaitingConfig.path = showWaitingAnimationPath;

    this.showService.currentState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: ShowState) => {
        if (state === ShowState.SHOW) {
          this.showStarted = true;
        }
    });

    this.showService.currentTrack()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((track: string) => {
        this.soundService.setAudioSource(track);
    });
  }

  ngOnDestroy(): void {
    this.soundService.setAudioSource(null);
  }

}
