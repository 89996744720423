import { Component, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { PlanningService } from '../../services/planning.service';
import { EventDate } from '../event-list/event-list.component';

@Component({
  selector: 'app-next-sessions',
  templateUrl: './next-sessions.component.html',
  styleUrls: ['./next-sessions.component.scss']
})
export class NextSessionsComponent implements OnChanges {

  @Input() locale: string;
  @Output() showFullCalendarClicked = new EventEmitter();

  public nextDateList: Date[];
  public nextDateEvent: EventDate[] = [];

  constructor(
    @Inject(PlanningService) private planningService: PlanningService
  ) {}

  ngOnChanges(): void {
    this.init();
    this.updateNextDateList(this.locale);
  }

  public openFullCalendar(): void {
    this.showFullCalendarClicked.emit();
  }

  private init(): void {
    this.nextDateList = this.planningService.getNextDates();
    this.updateNextDateList('fr');
  }

  private updateNextDateList(locale: string): void {
    if (!this.nextDateList) { return; }
    this.nextDateEvent = [];

    this.nextDateList.forEach((date: any) => {
      this.nextDateEvent.push({
        dayStr: formatDate(date, 'EEEE', locale),
        dayNb: formatDate(date, 'dd', locale),
        month: formatDate(date, 'MMMM yyyy', locale),
        hour: formatDate(date, 'HH:mm', locale),
      });
    });
  }

}
